/*
 * Copyright (C) 2019-2099 Deutsche Post DHL Group. All rights reserved.
 * This code is licensed and the sole property of Deutsche Post DHL Group.
 */

import { NavigationItemLevel1 } from "../../../types/Navigation";
import { NavigationL2Area } from "./NavigationL2Area";

type NavPrimL1FlyoutProps = {
  navItem: NavigationItemLevel1,
  onLeafClickAdditional: () => void
}
export const NavPrimL1Flyout = ({navItem, onLeafClickAdditional}: NavPrimL1FlyoutProps) => {
  if (navItem.children === undefined || navItem.children.length === 0) {
    return null; // don't render if it has no menu
  }
  return <div className="nav-primary-content">
    <NavigationL2Area entries={navItem.children} onLeafClickAdditional={onLeafClickAdditional} />
  </div>;
};

