/*
 * Copyright (C) 2019-2099 Deutsche Post DHL Group. All rights reserved.
 * This code is licensed and the sole property of Deutsche Post DHL Group.
 */

import "./DHLSessionCountdown.scss";
import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";
import classNames from "classnames";
import { DHLIcon, DHLLabel, DHLTooltip, useAuthenticationManager } from "@gkuis/gkp-base-widgets";
import { useTranslation } from "react-i18next";
import moment, { Duration, Moment } from "moment";

const VISIBILITY_REMAINING_TIME = moment.duration(15, "minutes");
const URGENT_REMAINING_TIME = moment.duration(3, "minutes");

export const DHLSessionCountdown = observer(() => {
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const [expirationTime, setExpirationTime] = useState<Moment | undefined>(undefined);
  const [remainingTime, setRemainingTime] = useState<Duration | undefined>(undefined);

  const authenticationManager = useAuthenticationManager();
  const {t} = useTranslation();

  const toggle = () => setTooltipOpen(!tooltipOpen);

  useEffect(
      () => {
        const updateExpirationTime = () => {
          if (authenticationManager.authenticated) {
            setExpirationTime(moment.unix(authenticationManager.getRefreshTokenParsed().exp));
          }
        };
        updateExpirationTime();
        return authenticationManager.addEventListener("onAuthRefreshSuccess", updateExpirationTime);
      },
      []
  );

  useEffect(
      () => {
        const updateRemainingTimeInterval = setInterval(
            () => setRemainingTime(expirationTime !== undefined ? moment.duration(expirationTime.diff(moment())) : undefined),
            1000
        );
        return () => clearInterval(updateRemainingTimeInterval);
      },
      [expirationTime]
  );

  if (!authenticationManager.authenticated || remainingTime === undefined || remainingTime > VISIBILITY_REMAINING_TIME || remainingTime.asSeconds() < 0) {
    return null;
  }

  return (
      <div
          id={`sessionCountdown`}
          className={classNames("sessionCountdown", remainingTime <= URGENT_REMAINING_TIME ? "urgent" : null)}
      >
        <DHLLabel
            name={`logout-in-label`}
            htmlFor={`sessionCountdown`}
            label={t("sessionCountdown.logoutInLabel")}
            className={classNames("logoutInLabel")}
        />
        <DHLLabel
            name={`countdown-label`}
            htmlFor={`sessionCountdown`}
            label={t("sessionCountdown.countdownLabel", {remainingTimeMinutes: Math.ceil(remainingTime.asMinutes())})}
            className={classNames("countdownLabel")} />
        <DHLIcon
            name={""}
            className={"icon"}
            icon={"refresh"}
        />
        <DHLLabel
            name={`refresh-link`}
            htmlFor={`sessionCountdown`}
            className={classNames("refreshLink")}
            label={t("sessionCountdown.refreshLinkLabel")}
            onClick={() => {
              authenticationManager.updateToken(-1);
              toggle();
            }}
        />
        <DHLTooltip
            className={"sessionCountdownTooltip"}
            placement={"bottom"}
            tooltipOpen={tooltipOpen}
            target={`sessionCountdown`}
            toggle={toggle}
        >
          {t("sessionCountdown.tooltip")}
        </DHLTooltip>
      </div>
  );
});