/*
 * Copyright (C) 2019-2099 Deutsche Post DHL Group. All rights reserved.
 * This code is licensed and the sole property of Deutsche Post DHL Group.
 */

import { useLocation, useNavigate, matchPath } from "react-router-dom";
import {useTranslation} from "react-i18next";
import {
  NavigationItemLevel1,
  NavigationItemLevel2,
  NavigationItemLevel3,
  NavigationItemLevel4
} from "../../../types/Navigation";
import classNames from "classnames";
import {NavPrimL1Flyout} from "./Flyout";

type NavPrimL1Props = {
  navItem: NavigationItemLevel1,
  isOpen: boolean,
  flyoutController: (menu: NavigationItemLevel1 | null, instantClose?: boolean) => void,
}
export const NavPrimL1 = ({navItem, isOpen, flyoutController}: NavPrimL1Props) => {
  const navigate = useNavigate();
  const {t} = useTranslation();
  const location = useLocation();

  const anyChildActive = selfActive(navItem, location)
    || navItem.children?.some((v)=>l2ChildActive(v,location))
    || anyBasePathActive(navItem, location);

  return (
    <div
      className={classNames("nav-p-l1", isOpen ? "open" : null, anyChildActive ? "active" : null)}
      onMouseEnter={() => flyoutController(navItem)}
      onMouseLeave={() => flyoutController(null, navItem.children == null)}
    >
      <div className="nav-p-l1-outerinner"
           tabIndex={0}
           onClick={() => {
             if (navItem.href !== undefined) {
               if (selfActive(navItem, location)) {
                 window.location.reload();
               } else {
                 navigate(navItem.href);
               }
             } else {
               navItem.onClick?.();
             }
           }}
           onMouseDown={(event) => !(navItem.href || navItem.onClick) ? event.preventDefault() : undefined}
      >
        <div className="nav-p-l1-inner">{t(navItem.key)}</div>
      </div>
      {isOpen && <NavPrimL1Flyout
          navItem={navItem}
          onLeafClickAdditional={() => flyoutController(null, true)}
      />}
    </div>
  );
};

type uLocation = ReturnType<typeof useLocation>;

function selfActive(navItem: NavigationItemLevel1, location: uLocation) {
  return navItem.href !== undefined && matchPath(navItem.href, location.pathname);
}

function l2ChildActive(l2Item: NavigationItemLevel2, location: uLocation) {
  return l2Item.children.some((v)=>l3ChildActive(v,location));
}

function l3ChildActive(l3Item: NavigationItemLevel3, location: uLocation) {
  return (l3Item.href !== undefined && matchPath({path: l3Item.href, end: false}, location.pathname))
    || (l3Item.children !== undefined && l3Item.children.some((v)=>l4ChildActive(v,location)));
}

function l4ChildActive(l4Item: NavigationItemLevel4, location: uLocation) {
  return l4Item.href !== undefined && matchPath(l4Item.href, location.pathname);
}

function anyBasePathActive(navItem: NavigationItemLevel1, location: uLocation) {
  if (!navItem.highlightPathPrefixes || !location.pathname) {
    return false;
  }
  return navItem.highlightPathPrefixes.some((it) => location.pathname.startsWith(it));
}