/*
 * Copyright (C) 2019-2099 Deutsche Post DHL Group. All rights reserved.
 * This code is licensed and the sole property of Deutsche Post DHL Group.
 */

import { AccessToken } from "@gkuis/gkp-authentication";
import { DHLButton, DHLContextmenu, DHLIcon, logger, useAuthenticationManager, useIsDesktopViewport } from "@gkuis/gkp-base-widgets";
import { NotificationWidget } from "@gkuis/gkp-notification-ui";
import classNames from "classnames";
import { observer } from "mobx-react-lite";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { matchPath, NavLink, useLocation, useNavigate } from "react-router-dom";
import { NavigationItemLevel4, PrimaryNavigation, TertiaryNavigation, TertiaryNavigationMyAcc } from "../../../types/Navigation";
import { usePopperGKEditionWithCloseOnClickOutside } from "./contextmenuHooks";
import "./DHLFrameHeader.scss";
import { MobileMenu } from "./MobileMenu";
import { useFlyoutMenuWithTimeout } from "./useFlyoutMenuWithTimeout";
import { Branding } from "../../../types/Branding";
import { NavPrimL1 } from "./NavPrimL1";
import { DHLSessionCountdown } from "../../molecules/DHLSessionCountdown/DHLSessionCountdown";


export type DHLHeaderProps = {
  mode: "internal" | "external",
  primaryNavigation: PrimaryNavigation,
  tertiaryNavigation: TertiaryNavigation,
  branding: Branding
}

export const DHLFrameHeader = observer((
    {
      mode,
      primaryNavigation,
      tertiaryNavigation,
      branding
    }: DHLHeaderProps
) => {
  const {t} = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const authenticationManager = useAuthenticationManager();
  // TODO irgendwann mobile Viewport auch automatisch nutzen, wenn nicht genügend Platz für alle Einträge Primär+Sekundär.
  const isDesktopViewport = useIsDesktopViewport();
  const [activeL1, setActiveL1] = useFlyoutMenuWithTimeout(null);
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const headerRef = useRef<HTMLElement | null>(null);
  const widthRef = useRef<HTMLElement | any>(null);

  const redirectToBrandingUri = () => {
    if (branding.logoRedirectUri.length > 0) { //Delete this later with the internal Frame logo link story
      if (matchPath({path: branding.logoRedirectUri, caseSensitive: true, end: true}, location.pathname)) {
        window.location.reload();
      } else {
        navigate(branding.logoRedirectUri);
      }
    }
  };

  useEffect(() => {
    if (isDesktopViewport) {
      setMobileMenuOpen(false);
    } else {
      setActiveL1(null);
    }
  }, [isDesktopViewport, setActiveL1]);

  useEffect(() => {
    if (mobileMenuOpen) {
      document.body.classList.add("dhlNav-mobile-open");
    } else {
      document.body.classList.remove("dhlNav-mobile-open");
    }
    // Cleanup-Function needed?, (can, but not needed probably)
  }, [mobileMenuOpen]);

  const loginButton = mode === "external" && !authenticationManager.authenticated
      && <DHLButton
          icon={"header-login"}
          iconPosition={"icon-first"}
          label={t("login")}
          tooltip={t("login.tooltip")}
          type={"primary"}
          onClick={() => authenticationManager.login()}
      />;


  return <header className="dhlFrameHeader" ref={headerRef}>
    <div className="dhlFrameHeader-inner">
      <div className="brand">
        <div className={
          classNames("branding-big",
              {withLandingPageRedirect: branding.logoRedirectUri.length > 0})} //Remove length check later with the internal Frame logo link story
             onClick={redirectToBrandingUri}>
          <DHLIcon name="" icon="co-branding-logo" />
        </div>
        <div className={
          classNames("branding-small", {withLandingPageRedirect: branding.logoRedirectUri.length > 0})}
            //Remove length check later with the internal Frame logo link story
             onClick={redirectToBrandingUri}>
          <DHLIcon name="" icon="responsive-co-branding-logo" />
        </div>
        <div className="branding-name">{t(branding.name)}</div>
      </div>
      <nav className="nav-tertiary">
        <DHLSessionCountdown />
        {loginButton}
        {tertiaryNavigation.myAccount
            && <NavTertMyAcc
                config={tertiaryNavigation.myAccount}
                headerRef={headerRef}
                widthRef={widthRef}
            />}
        {tertiaryNavigation.myMessages
            && <MyMessagesButton
                item={tertiaryNavigation.myMessages}
                onClickAdditional={() => setActiveL1(null, true)}
            />}
      </nav>
      <nav className="nav-primary">
        {primaryNavigation.items.map(item =>
            <NavPrimL1
                navItem={item}
                key={item.key}
                isOpen={item.key === activeL1?.key}
                flyoutController={setActiveL1}
            />
        )}
      </nav>
      <div className="nav-secondary">{/* no content definition for now */}</div>
      <div className="nav-mobile">
        {loginButton}
        {tertiaryNavigation.myMessages
            && <MyMessagesButton
                item={tertiaryNavigation.myMessages}
                onClickAdditional={() => setMobileMenuOpen(false)}
            />}
        <div
            tabIndex={0}
            className={classNames("nav-button-mmenu", {active: mobileMenuOpen})}
            onClick={() => setMobileMenuOpen((open) => !open)}
        >
          <DHLIcon name="" icon="menu" />
        </div>
      </div>
    </div>
    {!isDesktopViewport && mobileMenuOpen && <MobileMenu
        primaryNav={primaryNavigation}
        secondaryNav={{level1: []}}
        tertiaryNav={tertiaryNavigation}
        onNavigationCallback={() => setMobileMenuOpen(false)}
    />}
  </header>;
});

const MyMessagesButton = ({onClickAdditional}: {
  item: Required<TertiaryNavigation>["myMessages"],
  onClickAdditional: () => void
}) => {
  return <NotificationWidget onButtonClickCallback={onClickAdditional} />;
};

const NavTertMyAcc = ({
                        config: {items},
                        headerRef,
                        widthRef,
                      }: {
  config: TertiaryNavigationMyAcc,
  headerRef: React.RefObject<HTMLElement | null>
  widthRef: React.RefObject<HTMLElement | any>
}) => {
  const authenticationManager = useAuthenticationManager();
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastname] = useState("");
  const [width, setWidth] = useState();

  function setNames(token: AccessToken | undefined) {
    if (token === undefined) {
      setFirstName("");
      setLastname("");
    } else {
      setFirstName(token!.given_name!);
      setLastname(token!.family_name!);
    }
  }
  useEffect(() => {
    const updateNames = async () => {
      if (authenticationManager.authenticated) {
        const accessToken = await authenticationManager.getAccessTokenParsed();
        setNames(accessToken);
      } else {
        setNames(undefined);
      }
    }
    updateNames().catch(() => setNames(undefined));
  }, [authenticationManager]);

  const cm = usePopperGKEditionWithCloseOnClickOutside({
    placement: "bottom", modifiers: [
      {name: "offset", options: {offset: [0, 10 - 16]}}, // offset minus clickarea negative margin
      {
        name: "preventOverflow", options: {
          boundary: headerRef.current // stay horizontally inside header area
        }
      }
    ]
  });
  // FIXME should DHLContextmenu be modified so can be used directly as popper element? probably yes

  const isDesktopViewport = useIsDesktopViewport();
  const cmSetOpen = cm.setOpen;
  useEffect(() => {
    // close contextmenu when mobile/desktop switch happens.
    cmSetOpen(false);
  }, [isDesktopViewport, cmSetOpen]);

  useEffect(() => {
    setTimeout(() => {
      const newWidth = widthRef.current ? widthRef.current.clientWidth : 0;
      setWidth(newWidth);
    }, 1000);
  }, [cmSetOpen]);

  // TODO KEYBOARD: implement keyboard navigation like request or
  //  https://www.w3.org/TR/wai-aria-practices-1.2/#menubutton
  return <div ref={widthRef}>
    <div ref={cm.referenceElement}
         className={classNames("nav-tertiary-myAcc", {active: cm.open})}
         style={{"--Dwidth": width} as React.CSSProperties}
         tabIndex={0}
         data-testid="myAcc"
         onClick={cm.helperToggleOnClick}
         onMouseDown={(e) => e.preventDefault() /* do not let get focus */}
    >
      {firstName} {lastName}
      <DHLIcon name={""} icon={"arrow-down"} />
    </div>
    <div className={classNames("nav-tertiary-myAcc-menu", {visible: cm.open})}
         ref={cm.popperElement}
         style={cm.popperStyles} {...cm.popperAttributes}
    >
      <DHLContextmenu visible={true}>
        {items.map((item, index) =>
            <HeaderContextMenuItem item={item} key={index} onLeafClickAdditional={() => cm.setOpen(false)} />
        )}
      </DHLContextmenu>
    </div>
  </div>;
};

type HeaderContextMenuItemProps = {
  item: NavigationItemLevel4,
  onLeafClickAdditional: () => void,
}
export const HeaderContextMenuItem = ({item, onLeafClickAdditional}: HeaderContextMenuItemProps) => {
  const {t} = useTranslation();
  const location = useLocation();

  let icon = null;
  if (item.icon) {
    icon = <DHLIcon name={item.key} icon={item.icon} />;
  }
  if (item.href !== undefined) {
    return <NavLink
        to={item.href}
        className={({isActive}) => classNames("nav-l4-or-myAcc-cm-item") + (isActive ? " active" : "")}
        onClick={() => {
          onLeafClickAdditional();
          if (matchPath(location.pathname, item.href)) {
            window.location.reload();
          }
        }}
    >
      {icon}{t(item.key)}
    </NavLink>;
  } else if (item.onClick !== undefined) {
    return <div
        className={classNames("nav-l4-or-myAcc-cm-item")}
        tabIndex={0}
        onClick={() => {
          onLeafClickAdditional();
          item.onClick();
        }}
    >
      {icon}{t(item.key)}
    </div>;
  } else {
    logger.warn("unknown type nav-l4-or-myAcc-cm-item");
    return null;
  }
};