/*
 * Copyright (C) 2019-2099 Deutsche Post DHL Group. All rights reserved.
 * This code is licensed and the sole property of Deutsche Post DHL Group.
 */

import { DHLIcon } from "@gkuis/gkp-base-widgets";
import { useMemo, useState } from "react";
import {
  NavigationItemLevel1,
  NavigationItemLevel2,
  PrimaryNavigation,
  SecondaryNavigation,
  TertiaryNavigation,
  TertiaryNavigationMyAcc
} from "../../../types/Navigation";
import { NavigationL2Area } from "./NavigationL2Area";
import { useTranslation } from "react-i18next";
import { action, observable } from "mobx";
import { observer } from "mobx-react-lite";
import { useNavigate } from "react-router-dom";

const transformLevel2 = (items: NavigationItemLevel2[]): NavigationItemLevel2[] => {
  const result: NavigationItemLevel2[] = [];
  items.forEach(item => {
    // key === "" is special case and means merge with previous level 3 (desktop-view simulated multi-col)
    if (item.key === "" && result.length >= 1) {
      result[result.length - 1].children.push(...item.children);
    } else {
      result.push({...item, children: [...item.children]}); // create new obj with attrs that we modify!
    }
  });
  return result;
};

const convertPrimaryNavigation = (primaryNav: PrimaryNavigation): NavigationItemLevel1[] =>
    primaryNav.items.map(l1 =>
        (l1.children !== undefined)
            ? {...l1, children: transformLevel2(l1.children)}
            : {...l1}
    );

const convertTertiaryNavigationMyAcc = (tertiaryNavMyAcc?: TertiaryNavigationMyAcc): NavigationItemLevel1[] => {
  if(tertiaryNavMyAcc === undefined){
    return [];
  }
  return [{
    key: tertiaryNavMyAcc.key,
    children: [{key: "", children: tertiaryNavMyAcc.items}]
  }];
};

type MobileMenuProps = {
  primaryNav: PrimaryNavigation,
  secondaryNav: SecondaryNavigation,
  tertiaryNav: TertiaryNavigation,
  onNavigationCallback: () => void,
}

export const MobileMenu = observer(({primaryNav, tertiaryNav, onNavigationCallback}: MobileMenuProps) => {
  const {t} = useTranslation();
  const [navStack] = useState<(NavigationItemLevel1 & { children: NavigationItemLevel2[] })[]>(observable([]));
  const topLevelNav = useMemo(
      () => [...convertPrimaryNavigation(primaryNav), ...convertTertiaryNavigationMyAcc(tertiaryNav.myAccount) /* ,
       secondaryNav
       */],
      [primaryNav, tertiaryNav]
  );

  return <div className={"nav-mobile-content"}>
    {navStack.length >= 1 &&
    <div className={"content-subheader"} onClick={action(() => navStack.pop())}>
      <div className={"back-outer"} tabIndex={0}>
        <div className={"back"}>
          <DHLIcon name="" icon="arrow-back" />
          <span className={"text"}>{t("nav.back")}</span>
        </div>
      </div>
      <div className={"title"}>{t(navStack[navStack.length - 1].key)}</div>
      <div className="dummy">{/* structural relevant divs */}
        <div />
      </div>
    </div>
    }
    <div className={"content-content"}>
      {navStack.length === 0
          ? topLevelNav.map((value, index) =>
              <TopLevelItem
                  key={index}
                  item={value}
                  onNavigationCallback={onNavigationCallback}
                  onItemClicked={action((item) => navStack.push(item))} />
          )
          : <NavigationL2Area entries={navStack[navStack.length - 1].children} onLeafClickAdditional={onNavigationCallback} />}
    </div>
  </div>;
});

type TopLevelItemProps = {
  item: NavigationItemLevel1,
  onNavigationCallback: () => void,
  onItemClicked: (item: NavigationItemLevel1 & { children: NavigationItemLevel2[] }) => void
}

const TopLevelItem = ({
                        item,
                        onNavigationCallback,
                        onItemClicked
                      }: TopLevelItemProps
) => {
  const {t} = useTranslation();
  const navigate = useNavigate();
  return <div className="content-item-topLevel" tabIndex={0} onClick={() => {
    if (item.children !== undefined) {
      onItemClicked(item);
    } else if (item.href !== undefined) {
      onNavigationCallback();
      navigate(item.href);
    } else if (item.onClick !== undefined) {
      onNavigationCallback();
      item.onClick();
    }
  }}>
    {t(item.key)}
    {(item.children !== undefined) ? <DHLIcon name="" icon="arrow-forward" /> : null}
  </div>;
};