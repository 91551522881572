/*
 * Copyright (C) 2019-2099 Deutsche Post DHL Group. All rights reserved.
 * This code is licensed and the sole property of Deutsche Post DHL Group.
 */

import { matchPath, useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { NavigationItemBase } from "../../../types/Navigation";
import classNames from "classnames";

type NavSecondProps = {
  navItem: NavigationItemBase & { href: string }
}
export const NavSecond = ({navItem}: NavSecondProps) => {
  const navigate = useNavigate();
  const {t} = useTranslation();
  const location = useLocation();

  return (
      <div
          className={classNames("nav-s", selfActive(navItem, location) ? "active" : null)}
      >
        <div className="nav-s-outerinner"
             tabIndex={0}
             onClick={() => {
               if (navItem.href !== undefined) {
                 if (selfActive(navItem, location)) {
                   window.location.reload();
                 } else {
                   navigate(navItem.href);
                 }
               }
             }}
             onMouseDown={(event) => !(navItem.href) ? event.preventDefault() : undefined}
        >
          <div className="nav-s-inner">{t(navItem.key)}</div>
        </div>
      </div>
  );
};

type uLocation = ReturnType<typeof useLocation>;

function selfActive(navItem: { href: string }, location: uLocation) {
  return navItem.href !== undefined && matchPath(navItem.href, location.pathname);
}