/*
 * Copyright (C) 2019-2099 Deutsche Post DHL Group. All rights reserved.
 * This code is licensed and the sole property of Deutsche Post DHL Group.
 */

/*
  also it is not 100% stable in case is flickers or hangs between menus because scroll bar
 */
import {NavigationItemLevel1} from "../../../types/Navigation";
import {useCallback, useEffect, useRef, useState} from "react";
import {logger} from "@gkuis/gkp-base-widgets";

export const useFlyoutMenuWithTimeout =
    (initialMenu: NavigationItemLevel1 | null): [NavigationItemLevel1 | null,
      (menu: NavigationItemLevel1 | null, instantClose?: boolean) => void] =>
{
  const [activeL1, setActiveL1] = useState<NavigationItemLevel1 | null>(initialMenu);
  const timeoutTimer = useRef<ReturnType<typeof setTimeout> | null>(null);

  const setMenu = useCallback((menu: NavigationItemLevel1 | null, instantClose?: boolean): void => {
    if (timeoutTimer.current !== null) {
      clearTimeout(timeoutTimer.current);
    }
    if (menu != null || instantClose) {
      setActiveL1(menu);
      logger.log("set menu active", menu);
    } else {
      timeoutTimer.current = setTimeout(() => setActiveL1(null), 25);
      logger.log("menu leave, set timout");
    }
  }, [timeoutTimer, setActiveL1]);

  // cleanup timer on unmount
  useEffect(() => {
    return () => {
      if (timeoutTimer.current !== null) {
        clearTimeout(timeoutTimer.current);
      }
    };
  }, []);

  return [activeL1, setMenu];
};