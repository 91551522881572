/*
 * Copyright (C) 2019-2099 Deutsche Post DHL Group. All rights reserved.
 * This code is licensed and the sole property of Deutsche Post DHL Group.
 */

import { NavigationItemLevel2, NavigationItemLevel3 } from "../../../types/Navigation";
import { matchPath, NavLink, useLocation } from "react-router-dom";
import classNames from "classnames";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { DHLContextmenu, DHLIcon, logger, useIsTabletViewport, useViewportWidth } from "@gkuis/gkp-base-widgets";
import { useHelperOnClickOutside } from "../../organisms/DHLFrameHeader/contextmenuHooks";
import { HeaderContextMenuItem } from "../../organisms/DHLFrameHeader/DHLFrameHeader";

type NavigationL2AreaProps = {
  entries: NavigationItemLevel2[],
  onLeafClickAdditional: () => void
}
/**
 * can be used for desktop and mobile navigation
 * @param onLeafClickAdditional
 * @param entries
 * @constructor
 */
export const NavigationL2Area = ({onLeafClickAdditional, entries}: NavigationL2AreaProps) => {
  const {t} = useTranslation();
  const is2ColumnViewport = useIsTabletViewport();
  // the real width is not relevant, important that it changes on resize
  const viewportWidth = useViewportWidth(is2ColumnViewport);
  const areaRef = useRef<HTMLDivElement | null>(null);
  const [effMinMenuHeight, setEffMinMenuHeight] = useState(0);

  useEffect(() => { // if this effects produces scrollbars, first try to change to useLayoutEffect
    const children = areaRef.current?.children;
    if (areaRef.current === null || children === undefined || !is2ColumnViewport) {
      setEffMinMenuHeight(0);
      return;
    }

    const areaReservedHeight = sumComputedStylePropertyValues(areaRef.current, [
      "border-top-width",
      "border-bottom-width",
      "padding-top",
      "padding-bottom"
    ]);
    logger.log("area reserved height", areaReservedHeight);
    // if we want to do it a beautiful way, find implementation of https://stackoverflow.com/a/14129607
    const elementHeightsCumulative = [];
    let heightSum = 0;
    for (const child of children) {
      heightSum += computeEffectiveElementHeight(child);
      elementHeightsCumulative.push(heightSum);
    }
    const idealSize = heightSum / 2;
    const lastInFirstCol = elementHeightsCumulative.reduce((prev, curr) =>
        (Math.abs(curr - idealSize) < Math.abs(prev - idealSize)) ? curr : prev, 0
    );
    const computedSizePerCol: number[] = [
      lastInFirstCol,
      heightSum - lastInFirstCol
    ];

    const newMaxHeight = Math.max(...computedSizePerCol) + areaReservedHeight + 20; // 20px safety
    logger.log("menu height calc. child height cumulative", elementHeightsCumulative, "perCols", computedSizePerCol, " final", newMaxHeight);
    setEffMinMenuHeight(newMaxHeight);
  }, [setEffMinMenuHeight, is2ColumnViewport, viewportWidth]); // viewportWidth b/c L3-item line breaks

  return <div className="nav-p-l2-area" ref={areaRef} style={{height: effMinMenuHeight > 0 ? `${effMinMenuHeight}px` : undefined}}>
    {entries.map((navL2) =>
        <div className="nav-p-l2-column" key={navL2.key + "-column"}>
          <div className="nav-p-l2-headline">{t(navL2.key)}</div>
          {navL2.children?.map((navL3) => <NavL3Item key={navL3.key + "-L3"} navL3={navL3} onLeafClickAdditional={onLeafClickAdditional} />)}
        </div>
    )}
  </div>;
};

function computeEffectiveElementHeight(visibleElement: Element) {
  let height = (visibleElement instanceof HTMLElement)
      ? visibleElement.offsetHeight
      : visibleElement.clientHeight;

  logger.log(visibleElement, "beforeMargin", height);
  height += sumComputedStylePropertyValues(visibleElement, ["margin-top", "margin-bottom"]);
  logger.log(visibleElement, "afterMargin", height);

  return height;
}

function sumComputedStylePropertyValues(visibleElement: Element, properties: string[]): number {
  return properties
      .map(property => {
        const computed = parseInt(window.getComputedStyle(visibleElement).getPropertyValue(property), 10);
        if (isNaN(computed)) {
          logger.warn("not a number for", visibleElement, property);
          return 0;
        }
        return computed;
      })
      .reduce((p, c) => p + c, 0);
}

type NavL3ItemProps = {
  navL3: NavigationItemLevel3
  onLeafClickAdditional: () => void
}

const NavL3Item = ({navL3, onLeafClickAdditional}: NavL3ItemProps) => {
  const {t} = useTranslation();
  const location = useLocation();

  if (navL3.href !== undefined) {
    return <NavLink
        to={navL3.href}
        className={({isActive}) => classNames("nav-p-l3") + (isActive ? " active" : "")}
        onClick={() => {
          onLeafClickAdditional();
          if (matchPath({path: navL3.href, caseSensitive: true, end: true}, location.pathname)) {
            window.location.reload();
          }
        }}
    >
      {t(navL3.key)}
    </NavLink>;
  } else if (navL3.onClick !== undefined) {
    return <div
        className={classNames("nav-p-l3")}
        tabIndex={0}
        onClick={() => {
          onLeafClickAdditional();
          navL3.onClick();
        }}
    >
      {t(navL3.key)}
    </div>;
  } else {
    return <NavL3ItemWMenu navL3={navL3} onLeafClickAdditional={onLeafClickAdditional} />;
  }
};

type NavL3ItemWMenuProps = NavL3ItemProps & { navL3: Required<Pick<NavigationItemLevel3, "children">> }
const NavL3ItemWMenu = ({navL3, onLeafClickAdditional}: NavL3ItemWMenuProps) => {
  const {t} = useTranslation();
  const [menuOpen, setMenuOpen] = useState(false);
  const contextRef = useRef<HTMLDivElement | null>(null);
  const items = navL3.children;

  const {helperToggleOnClick} = useHelperOnClickOutside(contextRef, menuOpen, setMenuOpen);

  return <div className={"nav-p-l3-wMenu-outer"}>
    <div className={classNames("nav-p-l3 nav-p-l3-wMenu", {active: false, open: menuOpen})}
         tabIndex={0}
         onClick={(e) => {
           e.preventDefault(); // no browser focus on click...
           helperToggleOnClick(e);
         }}>
      <span>{t(navL3.key)}</span><DHLIcon name={""} icon={"arrow-down"} />
    </div>
    <div className="nav-p-l3-wMenu-outer-cm" ref={contextRef}>
      <DHLContextmenu visible={menuOpen}>
        {items.map((item) =>
            <HeaderContextMenuItem item={item} key={item.key + "-context"} onLeafClickAdditional={onLeafClickAdditional} />
        )}
      </DHLContextmenu>
    </div>
  </div>;
};