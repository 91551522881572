/*
 * Copyright (C) 2019-2099 Deutsche Post DHL Group. All rights reserved.
 * This code is licensed and the sole property of Deutsche Post DHL Group.
 */

import singleSpaReact from "single-spa-react";
import React from "react";
import ReactDOM from "react-dom";
import "./i18n/i18n";
import { Root } from "./components/organisms/Root/Root";
import { DHLFatalErrorPage } from "@gkuis/gkp-base-widgets";

export type { RootProps } from "./components/organisms/Root/Root";
export type {
  PrimaryNavigation,
  SecondaryNavigation,
  TertiaryNavigation,
  NavigationItemBase,
  NavigationItemLevel1,
  NavigationItemLevel2,
  NavigationItemLevel3,
  NavigationItemLevel4
} from "./types/Navigation";
export type { Branding } from "./types/Branding";

const reactLifecycles = singleSpaReact({
  React,
  ReactDOM,
  rootComponent: Root,
  errorBoundary(err, info, props) {
    return <DHLFatalErrorPage />;
  },
});

export const bootstrap = reactLifecycles.bootstrap;
export const mount = reactLifecycles.mount;
export const unmount = reactLifecycles.unmount;
