/*
 * Copyright (C) 2019-2099 Deutsche Post DHL Group. All rights reserved.
 * This code is licensed and the sole property of Deutsche Post DHL Group.
 */

import { TertiaryNavigationMyAcc } from "../../../types/Navigation";
import classNames from "classnames";
import { DHLContextmenu, DHLIcon, useAuthenticationManager, useIsDesktopViewport } from "@gkuis/gkp-base-widgets";
import { useEffect, useState } from "react";
import { AccessToken } from "@gkuis/gkp-authentication";
import { usePopperGKEditionWithCloseOnClickOutside } from "../../organisms/DHLFrameHeader/contextmenuHooks";
import { HeaderContextMenuItem } from "../../organisms/DHLFrameHeader/DHLFrameHeader";

type NavTertMyAccProps = {
  config: TertiaryNavigationMyAcc,
  headerRef: React.RefObject<HTMLElement | null>
  widthRef: React.RefObject<any>
}

export const NavTertMyAcc = ({
                               config: {items},
                               headerRef,
                               widthRef,
                             }: NavTertMyAccProps) => {
  const authenticationManager = useAuthenticationManager();
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [width, setWidth] = useState();

  function setNames(token: AccessToken | undefined) {
    if (token === undefined) {
      setFirstName("");
      setLastName("");
    } else {
      setFirstName(token.given_name);
      setLastName(token.family_name);
    }
  }

  useEffect(() => {
    const updateNames = async () => {
      if (authenticationManager.authenticated) {
        const accessToken = await authenticationManager.getAccessTokenParsed();
        setNames(accessToken);
      } else {
        setNames(undefined);
      }
    };
    updateNames().catch(() => setNames(undefined));
  }, [authenticationManager]);

  const cm = usePopperGKEditionWithCloseOnClickOutside({
    placement: "bottom", modifiers: [
      {name: "offset", options: {offset: [0, 10 - 16]}}, // offset minus clickarea negative margin
      {
        name: "preventOverflow", options: {
          boundary: headerRef.current // stay horizontally inside header area
        }
      }
    ]
  });
  // FIXME should DHLContextmenu be modified so can be used directly as popper element? probably yes

  const isDesktopViewport = useIsDesktopViewport();
  const cmSetOpen = cm.setOpen;
  useEffect(() => {
    // close contextmenu when mobile/desktop switch happens.
    cmSetOpen(false);
  }, [isDesktopViewport, cmSetOpen]);

  useEffect(() => {
    setTimeout(() => {
      const newWidth = widthRef.current ? widthRef.current.clientWidth : 0;
      setWidth(newWidth);
    }, 1000);
  }, [cmSetOpen]);

  // TODO KEYBOARD: implement keyboard navigation like request or
  //  https://www.w3.org/TR/wai-aria-practices-1.2/#menubutton
  return <div ref={widthRef}>
    <div ref={cm.referenceElement}
         className={classNames("nav-tertiary-myAcc", {active: cm.open})}
         style={{"--Dwidth": width} as React.CSSProperties}
         tabIndex={0}
         data-testid="myAcc"
         onClick={cm.helperToggleOnClick}
         onMouseDown={(e) => e.preventDefault() /* do not let get focus */}
    >
      {firstName} {lastName}
      <DHLIcon name={""} icon={"arrow-down"} />
    </div>
    <div className={classNames("nav-tertiary-myAcc-menu", {visible: cm.open})}
         ref={cm.popperElement}
         style={cm.popperStyles} {...cm.popperAttributes}
    >
      <DHLContextmenu visible={true}>
        {items.map((item) =>
            <HeaderContextMenuItem item={item} key={item.key + "-context"} onLeafClickAdditional={() => cm.setOpen(false)} />
        )}
      </DHLContextmenu>
    </div>
  </div>;
};
