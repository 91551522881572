/*
 * Copyright (C) 2019-2099 Deutsche Post DHL Group. All rights reserved.
 * This code is licensed and the sole property of Deutsche Post DHL Group.
 */

import {
  NavigationItemBase,
  NavigationItemLevel1,
  NavigationItemLevel2,
  NavigationItemLevel3,
  NavigationItemLevel4,
  PrimaryNavigation
} from "../types/Navigation";

export async function isEnabled(item: NavigationItemBase, currentScopes: string[]): Promise<boolean> {
  return item.enabled === undefined || (typeof item.enabled === "function" ? item.enabled(currentScopes) : currentScopes.includes(item.enabled));
}

export async function filterNavItem<T extends NavigationItemLevel1 | NavigationItemLevel2 | NavigationItemLevel3 | NavigationItemLevel4>(navItem: T, scopes: string[]): Promise<T | undefined> {
  if (!await isEnabled(navItem, scopes)) {
    return undefined;
  }

  const filteredChildren = await filterNavItems<NavigationItemLevel2 | NavigationItemLevel3 | NavigationItemLevel4>(navItem.children ?? [], scopes);
  return navItem.href !== undefined || navItem.onClick !== undefined || filteredChildren.length > 0
      ? {
        ...navItem,
        children: navItem.children === undefined ? undefined : filteredChildren
      }
      : undefined;
}

function isNotUndefined<T>(item: T | undefined): item is T {
  return item !== undefined;
}

export async function filterNavItems<T extends NavigationItemLevel1 | NavigationItemLevel2 | NavigationItemLevel3 | NavigationItemLevel4>(
    navItems: T[],
    scopes: string[]
): Promise<T[]> {
  return (await Promise.all(navItems.map(item => filterNavItem(item, scopes))))
      .filter(isNotUndefined);
}

/**
 * process and filter primary navigation
 * in level 2, some sections can be multi-column, where the not-firsts columns have no name. but if because of
 * permissions all entries of the first columns are filtered away, the headline is missing for the other columns.
 * so we have to add the name back.
 *
 * caveat of the current approach: can't define L2 name as always intentionally empty or have same headline next to
 * another using same l10n-key
 * @param primaryNavigationArg primary navigation object
 * @param scopes actual permissions of user
 */
export const processAndFilterPrimaryNavigation = async (primaryNavigationArg: PrimaryNavigation, scopes: string[]) => {
  const cloned: PrimaryNavigation = {...primaryNavigationArg};
  // clone to incl. ItemLevel2 itself
  cloned.items = cloned.items.map((l1) => {
    return {
      ...l1, children: l1.children?.map((l2) => {
        return {...l2};
      })
    } as NavigationItemLevel1;
  });

  // pre-process L2 name: add where empty
  for (const l1 of cloned.items) {
    if (!l1.children) {
      continue;
    }
    //loop forwards
    for (let i = 0; i < l1.children.length; i++) {
      if (i > 0 && (l1.children[i].key == null || l1.children[i].key === "")) {
        l1.children[i].key = l1.children[i - 1].key;
      }
    }
  }

  const filtered: PrimaryNavigation = {items: await filterNavItems(cloned.items, scopes)};

  // delete L2 name if same as previous
  for (const l1 of filtered.items) {
    if (!l1.children) {
      continue;
    }
    // loop backwards
    for (let i = l1.children.length - 1; i >= 0; i--) {
      if (i > 0 && l1.children[i].key === l1.children[i - 1].key) {
        l1.children[i].key = "";
      }
    }
  }
  return filtered;
};