/*
 * Copyright (C) 2019-2099 Deutsche Post DHL Group. All rights reserved.
 * This code is licensed and the sole property of Deutsche Post DHL Group.
 */

import {
  MouseEvent as ReactMouseEvent,
  CSSProperties,
  Dispatch,
  MutableRefObject,
  SetStateAction,
  useEffect,
  useLayoutEffect,
  useRef,
  useState
} from "react";
import {PopperProps, usePopper} from "react-popper";
import {logger} from "@gkuis/gkp-base-widgets";

export type PopperPlacement = PopperProps<unknown>["placement"]

type PopperGKEditionArguments = {
  placement: PopperPlacement
  modifiers?: PopperProps<unknown>["modifiers"]
}

type PopperGKEditionReturns = {
  open: boolean,
  setOpen: Dispatch<SetStateAction<boolean>>,
  referenceElement: MutableRefObject<HTMLDivElement | null>,
  popperElement: MutableRefObject<HTMLDivElement | null>
  popperStyles: CSSProperties,
  popperAttributes: { [p: string]: string } | undefined,
}

const usePopperGKEditionInternal = ({placement, modifiers}: PopperGKEditionArguments) => {
  const [open, setOpen] = useState(false);
  const referenceElement = useRef<HTMLDivElement>(null);
  const popperElement = useRef<HTMLDivElement>(null);
  const {styles, attributes, forceUpdate: forceUpdatePopper} = usePopper(
      referenceElement.current,
      popperElement.current, {
    placement,
    modifiers
  });

  useLayoutEffect(() => {
    // update synchronously
    open && forceUpdatePopper?.();
  }, [open, forceUpdatePopper]);

  return {
    open,
    setOpen,
    referenceElement,
    popperElement,
    popperStyles: styles.popper,
    popperAttributes: attributes.popper,
  };
};

export const usePopperGKEditionWithCloseOnClickOutside =
    (args: PopperGKEditionArguments): PopperGKEditionReturns & ReturnType<typeof useHelperOnClickOutside> => {
      const original = usePopperGKEditionInternal(args);
      const {popperElement, open, setOpen} = original;

      const {ignoreEventRef} = useHelperOnClickOutside(popperElement, open, setOpen);

      const helperToggleOnClick = (e: ReactMouseEvent) => {
        setOpen(o => !open);
        ignoreEventRef.current = e.nativeEvent;
      };

      return {...original, ignoreEventRef, helperToggleOnClick};
    };

/**
 * hook to help close contextmenu (or other things) when clicked outside of the menu/a element.
 *
 * @returns ignoreEventRef: use to ignore a specific click event (needs to be native, not the react one).
 * @returns helperToggleOnClick: optionally use as drop-in onClick-function that does the work for you
 *
 * @param element where the boundary between inside and outside for a event is
 * @param open should this helper be active and listen for click events
 * @param setOpen toggles when used from helperToggleOnClick, called with false on click outside
 */
export const useHelperOnClickOutside = (
    element: MutableRefObject<HTMLElement | null | undefined>,
    open: boolean,
    setOpen: Dispatch<SetStateAction<boolean>>,
): {
  ignoreEventRef: MutableRefObject<MouseEvent | undefined>,
  helperToggleOnClick: (e: ReactMouseEvent) => void,
} => {
  const ignoreEventRef = useRef<MouseEvent | undefined>();

  useEffect(() => {
    if (open) {
      const clickIsInsideThisComponent = (event: MouseEvent) => {
        return (event.target instanceof Element)
            ? element.current?.contains(event.target)
            : false; // not a typical click
      };
      const onClickOutside = (event: MouseEvent) => {
        if (event !== ignoreEventRef.current && !clickIsInsideThisComponent(event)) {
          logger.log("close contextmenu because click outside");
          setOpen(false);
        }
      };

      document.addEventListener("click", onClickOutside);
      return () => document.removeEventListener("click", onClickOutside);
    } else {
      return () => {
      };
    }
  }, [ignoreEventRef, element, open, setOpen]);

  const helperToggleOnClick = (e: ReactMouseEvent) => {
    setOpen(o => !open);
    ignoreEventRef.current = e.nativeEvent;
  };

  return {ignoreEventRef, helperToggleOnClick};
};